import React from 'react';
import { Col, Row, Form, Input, Button, Select, Space, Typography, message, theme } from 'antd';
import { CRMConnectionData } from '../AppState';

const CRMConnection = ({ prev, next, apiConnection }) => {
    const { token } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const [crm, setCrm] = React.useState('');

    React.useEffect(() => {
        CRMConnectionData.value['crm'].value !== '' && setCrm(CRMConnectionData.value['crm'].value);
    }, []);

    const onFinish = async (values) => {
        const key = 'updatable';
        messageApi.open({
            key,
            type: 'loading',
            content: 'Validating API key...'
        });

        apiConnection.init(values['crm'], { apikey_accesstoken: values['apikey'] })
        let response = await apiConnection.testConnection();

        if (response) {
            messageApi.open({
                key,
                type: 'success',
                content: 'Great! API key is valid!',
                duration: 1
            }).then(() => {
                for (const key in values) {
                    if (Object.hasOwnProperty.call(values, key)) {
                        CRMConnectionData.value[key].value = values[key];
                    }
                }
                next();
            });
        } else {
            messageApi.open({
                key,
                type: 'error',
                content: 'Invalid API Key. API Key Must be The Primary Follow up Boss Owner Account',
            });
        }

    }
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            {contextHolder}
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '20px', color: token.colorLink }}>Your CRM & API Key</Typography.Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Text style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', display: 'block' }}>The Homebot | CRM Integration is for 1 CRM Account Only!<br /> To Setup Multiple CRM Accounts, You Will Need to Complete This Web-Form for Each Account Separately.<br />Using A Different CRM Account & Unique API Key</Typography.Text>
                    <br /><br />
                </Col>
            </Row>
            <Row gutter={24}>

                <Col sm={{span: 8, offset: 4}} span={16} offset={4}>
                    <Form.Item label="CRM" name="crm" rules={[{ required: true, message: 'Select Your CRM' }]} initialValue={CRMConnectionData.value['crm'].value}>
                        <Select
                            style={{ width: '100%', textAlign: 'left' }}
                            onChange={(value) => setCrm(value)}
                            options={[
                                { value: 'follow-up-boss', label: 'Follow up Boss' }
                                //{ value: 'sierra-interactive', label: 'Sierra Interactive'},
                                //{ value: 'chime', label: 'Chime/Lofty'},
                                //{ value: 'kvcore', label: 'KvCore'}
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col sm={{span: 8, offset: 0}} span={16} offset={4}>
                    <Form.Item label={<>API Key &nbsp;<Typography.Link target="_blank" href={apiConnection.getDocumentationUrls()[crm]}>Need Help finding API Key?</Typography.Link></>} name="apikey" rules={[{ required: true, message: 'Please Input The API Key from The Primary Owner Account!' }]} initialValue={CRMConnectionData.value['apikey'].value}>
                        <Input />
                    </Form.Item>
                    <Typography.Text style={{ fontStyle: 'italic', fontSize: 'smaller', marginTop: '0px', display: 'block' }}>
                        API Key must be from Primary Owner Account
                    </Typography.Text>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col sm={{span: 8, offset: 4}} span={16} offset={4}>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" style={{ width: '100%', height: '32px', fontWeight: 'bold' }}>
                                Next
                            </Button>
                            <Button onClick={() => prev()}>
                                Previous
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default CRMConnection;