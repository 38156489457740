import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Space, Typography, Radio, Divider, theme } from 'antd';
import { ContactInfoData, CRMConnectionData, UsersData } from '../AppState';

const Submit = ({ prev, next }) => {
    const { token } = theme.useToken();
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [bulkUpload, setBulkUpload] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [payloadJSON, setPayloadJSON] = useState('');

    useEffect(() => {
        let payload = {'ContactInfoData': {}, CRMConnectionData: {}, AccountsData: []};
        for (const key in ContactInfoData.value) {
            payload['ContactInfoData'][key] = ContactInfoData.value[key].value
        } 
        for (const key in CRMConnectionData.value) {
            payload['CRMConnectionData'][key] = CRMConnectionData.value[key].value
        } 
        payload['AccountsData'] = UsersData.value;
        payload['BulkUpload'] = bulkUpload;

        console.log('Final payload:', payload);
        setPayloadJSON(JSON.stringify(payload));
      }, [bulkUpload]);

    const onFinish = async () => {
        setSubmitting(true);

        console.log(payloadJSON);
    
        // Send payload to https://webhook.site/New-Testing
        let response = await fetch('https://homebot-osirs-crm-sync.azurewebsites.net/api/Setup-1-NewAccount-Received', {
             method: 'POST',
             headers: {
                 "Content-Type": "application/json",
             },
             redirect: "follow",
             body: payloadJSON,
         });
         setSubmitting(false);
         if(response.ok){
             setSubmitted(true);
        } else {
	        setErrorText('Failed to submit payload data. Please try again.');
	    }
    };

    return (
        <Form layout="vertical" onFinish={onFinish}>
            {/* Only show the form content if not submitted */}
            {!isSubmitted && (
                <>
                    <Row gutter={24}>
                        <Col span={16} offset={4}>
                            <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '40px', color: token.colorLink }}>
                                Add Contacts & Submit To Activate Integration
                            </Typography.Title>
                        </Col>
                    </Row>
    
                    {/* Question: Import in mass Contacts? */}
                    <Row gutter={24}>
                        <Col span={16} offset={4}>
                            <Typography.Text style={{ fontSize: '18px', fontWeight: 'normal', marginBottom: '10px', display: 'block' }}>
                                Import All Seller Contacts in CRM to Homebot?
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8} offset={4}>
                            <Radio.Group
                                onChange={(e) => setBulkUpload(e.target.value)}
                                style={{ marginLeft: '8px' }}
                            >
                                <Radio.Button value="Yes">Yes</Radio.Button>
                                <Radio.Button value="No">No</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
    
                    <Row gutter={24}>
                        <Col span={12} offset={6}>
                            <Divider style={{ backgroundColor: 'white', height: '1px', marginTop: '30px', marginBottom: '20px', textAlign: 'center' }} />
                        </Col>
                    </Row>
    
                    {/* If Bulk Upload (Yes) is selected */}
                    {bulkUpload === 'No' && (
                        <>
                            <Row gutter={24}>
                                <Col span={16} offset={4}>
                                    <Typography.Text style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal', marginTop: '20px', marginBottom: '10px', display: 'block' }}>
                                        Add the tag-  "
                                        <Typography.Text strong style={{ fontSize: '17px' }}>
                                            Homebot_Import
                                        </Typography.Text>
                                        "  to your Contacts and after you submit, they will be imported to Homebot upon activation.
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </>
                    )}
    
                    {/* Submission Area */}
                    <Row gutter={24}>
                        <Col span={16} offset={4}>
                            <Form.Item style={{ textAlign: 'center', marginTop: 10 }}>
                                <Space>
                                    <Typography.Text type="danger">{errorText}</Typography.Text>
                                </Space>
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center', marginTop: 10 }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    <Button disabled={isSubmitting} onClick={() => prev()}>
                                        Previous
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
    
            {/* Footer Message */}
            <Row gutter={24}>
                <Col span={10} offset={7}>
                    <Typography.Title
                        italic
                        level={5}
                        style={{ textAlign: 'center', marginTop: '50px', fontSize: '20px' }}
                        type="secondary"
                    >
                        {!isSubmitted
                            ? ''
                            : 'Thank You! Expect Your Integration to be Activated within the next 48 Hours!'}
                    </Typography.Title>
                </Col>
            </Row>
        </Form>
    );
};

export default Submit;
