import React from 'react';
import { Col, Row, Form, Input, Button, Typography, Space, Radio, theme } from 'antd';
import { ContactInfoData } from '../AppState';

const ContactInfo = ({ prev, next }) => {
    const { token } = theme.useToken();

    const onFinish = (values) => {
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                ContactInfoData.value[key].value = values[key];
            }
        }
        next();
    }

    const existingAccountChange = (e) => {
        const value = e.target.value;
        ContactInfoData.value['existingAccount'].value = value === "Yes" ? "Yes" : "No";
    }
    const newUpdateAccountChange = (e) => {
        const value = e.target.value;
        ContactInfoData.value['newOrUpdateIntegration'].value = value === "Update" ? "Update" : "New";
    }
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '2px', marginBottom: '30px', color: token.colorLink }}>Enter Your Homebot & Contact Info To Begin</Typography.Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col sm={{ span: 8, offset: 4 }} span={16} offset={4}>
                    <Form.Item label="Your Homebot Account's Organization/Team Name" name="teamname" rules={[{ required: true, message: 'Homebot Account Organization/Team Name Required' }]} initialValue={ContactInfoData.value['teamname'].value}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col sm={{ span: 8, offset: 0 }} span={16} offset={4}>
                    <Form.Item label="Homebot Primmary Account Email" name="email" rules={[{ required: true, type: 'email', message: 'Primary Homebot Account Email Required' }]} initialValue={ContactInfoData.value['email'].value}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col sm={{ span: 8, offset: 4 }} span={16} offset={4}>
                    <Form.Item label="First Name" name="firstname" rules={[{ required: true, message: 'First Name Required' }]} initialValue={ContactInfoData.value['firstname'].value}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col sm={{ span: 8, offset: 0 }} span={16} offset={4}>
                    <Form.Item label="Last Name" name="lastname" rules={[{ required: true, message: 'Last Name Required' }]} initialValue={ContactInfoData.value['lastname'].value}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6} offset={4}>
                    <Form.Item
                        label="Are you an Existing Homebot Integration User?"
                        name="existingAccount"
                        rules={[{ required: true, message: 'select Yes or No' }]}
                        initialValue={ContactInfoData.value['existingAccount'].value === "Yes" ? "Yes" : "No"}
                        style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 'medium', marginLeft: '10px', marginTop: '30px', marginBottom: '10px' }}
                    >
                        <Radio.Group
                            onChange={existingAccountChange}
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Radio value="No" style={{ marginLeft: '10px', marginBottom: '3px', fontSize: '14px', fontWeight: 'normal' }}>
                                No, I'm a New User
                            </Radio>
                            <Radio value="Yes" style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'normal' }}>
                                Yes, I Currently Have an Integration
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={24}>
                <Col span={8} offset={4}>
                    <Form.Item
                        label="New Integration Setup or Update an Existing Integration"
                        name="newOrUpdateIntegration"
                        rules={[{ required: true, message: 'Select Option' }]}
                        initialValue={ContactInfoData.value['newOrUpdateIntegration'].value === "Update" ? "Update" : "New"}
                        style={{ fontWeight: 'bold', marginLeft: '10px', marginTop: '30px', marginBottom: '50px' }}
                    >
                        <Radio.Group
                            onChange={newUpdateAccountChange}
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Radio value="New" style={{ marginLeft: '10px', marginBottom: '3px', fontSize: '14px', fontWeight: 'normal' }}>
                                Setup New
                            </Radio>
                            <Radio value="Update" style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'normal' }}>
                                Update Existing Setup
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col sm={{ span: 8, offset: 4 }} span={16} offset={4}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '40%', height: '36px', fontWeight: 'bold' }}>
                            Continue
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default ContactInfo;