import { signal } from "@preact/signals-react";

const ContactInfoData = signal({
    'teamname': signal(''),
    'firstname': signal(''),
    'lastname': signal(''),
    'email': signal(''),
    'existingAccount': signal(''),
    'newOrUpdateIntegration': signal('')
});

const CRMConnectionData = signal({
    'crm': signal(''),
    'apikey': signal('')
});

const UsersData = signal([]);

export {ContactInfoData, CRMConnectionData, UsersData}