import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Space, Typography, Input, InputNumber, Table, Checkbox, Divider, theme, Alert } from 'antd';
import { ContactInfoData, UsersData } from '../AppState';

const Users = ({ prev, next, apiConnection }) => {
    const { token } = theme.useToken();
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [secondaryAccounts, setSecondaryAccounts] = useState(0); // Tracks the number of secondary accounts
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isChecked, setIsChecked] = useState(false); // Tracks email confirmation checkbox
    const [isConfirmAccountsChecked, setIsConfirmAccountsChecked] = useState(false); // Tracks secondary accounts confirmation checkbox
    const [errorMessage, setErrorMessage] = useState(''); // Tracks error messages

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        }
    ];

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let selectData = await apiConnection.getUsers();
                setData(selectData);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [apiConnection]);

    const validateSelectedRows = () => {
        if (secondaryAccounts > 0 && selectedRows.length !== secondaryAccounts) {
            setErrorMessage(
                `You selected ${secondaryAccounts} Additional Account${secondaryAccounts > 1 ? 's' : ''}, please label which user${secondaryAccounts > 1 ? 's' : ''} has the additional Homebot account${secondaryAccounts > 1 ? 's' : ''}.`
            );
            return false;
        }
        setErrorMessage('');
        return true;
    };

    const onFinish = async (values) => {
        if (!validateSelectedRows()) return; // Prevent submission if validation fails
        ContactInfoData.value['email'].value = values['email'];
        if (selectedRows.length <= secondaryAccounts) {
            UsersData.value = selectedRows;
        }
        next();
    };

    // Handle email confirmation checkbox change
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked); // Update isChecked state based on the checkbox
    };

    // Handle the new "Confirm # of accounts" checkbox change
    const handleConfirmAccountsChange = (e) => {
        setIsConfirmAccountsChecked(e.target.checked);
    };

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '10px', color: token.colorLink }}>
                        Confirm Homebot Accounts
                    </Typography.Title>
                    <Typography.Text style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'italic', marginBottom: '20px', display: 'block' }}>
                        Within The Same CRM Account, You Can Connect Multiple User Homebot Accounts<br /> Let's Confirm Your Primary Homebot Account and Set Up Any Additional User Homebot Accounts to use with Your CRM.
                    </Typography.Text>
                    <br />
                </Col>
            </Row>
            
            {/* Primary email confirmation */}
            <Row gutter={24}>
                <Col sm={{span: 12, offset: 4}} span={16} offset={4}>
                    <Form.Item
                        label={
                            <span style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 'medium', marginBottom: '0px', display: 'block' }}>
                                Confirm Your Primary Homebot Account Email
                            </span>
                        }
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Primary Homebot Account Email Required' }]}
                        initialValue={ContactInfoData.value['email'].value}
                        style={{ marginBottom: '8px' }}  // Reduce margin below the email input box
                    >
                        <Input />
                    </Form.Item>
                </Col>

                {/* Checkbox with offset */}
                <Col sm={{span: 12, offset: 4}} span={16} offset={4}>
                    <Form.Item
                        name="confirmEmail"
                        valuePropName="checked"
                        rules={[{ required: true, message: 'Please confirm that the email is correct.' }]}
                        style={{ marginLeft: '20px', marginTop: '0px' }}  // Remove the top margin of the checkbox
                    >
                        <Checkbox onChange={handleCheckboxChange}>Confirm correct email</Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Divider style={{ backgroundColor: 'white', height: '1px', marginTop: '2px', marginBottom: '10px' }} />
                </Col>
            </Row>

            {/* Conditionally render additional content based on isChecked */}
            {isChecked && (
                <>
                    <Row gutter={24}>
                        <Col span={14} offset={4}>
                            <Typography.Text
                                style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', fontWeight: 'bold', display: 'block' }}>
                                You may choose to set up more secondary Homebot Accounts in addition to the primary account, within the same CRM. For example, each agent within your CRM could have their own Homebot Account.
                            </Typography.Text>
                            <br /><br />
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12} offset={4}>
                            <Typography.Text
                                style={{ fontStyle: 'normal', fontSize: 'medium', fontWeight: 'normal', marginTop: '5px', marginBottom: '3px', display: 'block' }}>
                                # of additional Homebot Accounts to Set Up <br />
                                <InputNumber min={0} defaultValue={secondaryAccounts} onChange={setSecondaryAccounts} />
                            </Typography.Text>
                            <Typography.Text
                                style={{ fontStyle: 'italic', fontSize: 'smaller', fontWeight: 'lighter', marginTop: '10px', marginBottom: '15px', display: 'block' }}>
                                No Additional Accounts? Enter 0. Agents within your CRM have their own separate Homebot Account? Enter 1 for each additional account.
                            </Typography.Text>
                        </Col>
                    </Row>

                    {/* Conditionally render the table if there are secondary accounts */}
                    {secondaryAccounts > 0 && (
                        <>
                            <Row gutter={24}>
                                <Col span={16} offset={4}>
                                    <Table
                                        size='small'
                                        pagination={false}
                                        loading={isLoading}
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        dataSource={data}
                                        scroll={{ y: 200 }}
                                    />
                                    <br />
                                </Col>
                            </Row>
                            
                            {/* Render Confirm # of accounts checkbox AFTER table if secondaryAccounts > 0 */}
                            <Row gutter={24}>
                                <Col span={16} offset={4}>
                                    <Form.Item
                                        name="confirmAccounts"
                                        valuePropName="checked"
                                        rules={[{ required: true, message: 'Please confirm the number of accounts.' }]}
                                        style={{ marginLeft: '20px', marginTop: '5px', marginBottom: '30px' }}
                                    >
                                        <Checkbox onChange={handleConfirmAccountsChange}>Confirm # of accounts</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {/* Render Confirm # of accounts checkbox BEFORE table if secondaryAccounts is 0 */}
                    {secondaryAccounts === 0 && (
                        <Row gutter={24}>
                            <Col span={12} offset={4}>
                                <Form.Item
                                    name="confirmAccounts"
                                    valuePropName="checked"
                                    rules={[{ required: true, message: 'Please confirm the number of accounts.' }]}
                                    style={{ marginLeft: '20px', marginTop: '5px', marginBottom: '15px' }}
                                >
                                    <Checkbox onChange={handleConfirmAccountsChange}>Confirm # of accounts</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {/* Error message */}
                    {errorMessage && (
                        <Row gutter={24}>
                            <Col span={16} offset={4}>
                                <Alert message={errorMessage} type="error" showIcon />
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {/* Form Submit buttons (conditionally render Next button based on "Confirm # of accounts" checkbox) */}
            <Row gutter={24}>
                <Col span={16} offset={4}>
                    <Form.Item>
                        <Space>
                            {isConfirmAccountsChecked && ( // Only show Next button when checkbox is checked and no validation errors
                                <Button type="primary" htmlType="submit">
                                    Next
                                </Button>
                            )}
                            <Button onClick={() => prev()}>
                                Previous
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default Users;
