class ApiConnection {

    init(crm, auth){
        this.crm = crm;
        this.setAuthHeader(auth);        
    }

    getDocumentationUrls(){
        return {
            'follow-up-boss': 'https://app.followupboss.com/2/api',
            'sierra-interactive': 'https://client.sierrainteractivedev.com/integrations.aspx#direct',
            'chime': 'https://help.lofty.com/hc/en-us/sections/115000576843-Integrations',
            'kvcore': 'https://help.insiderealestate.com/en/articles/4263959-kvcore-api-tokens'
        }
    }

    setAuthHeader(auth){
        const authHeader = {
            'follow-up-boss': {
                accept: 'application/json',
                authorization: `Basic ${btoa(auth.apikey_accesstoken+':')}`,
                xSystem: 'Homebot-Osirs',
                xSystemKey: '2d71010940e3b1e079cf8a7ad7060adc'
            },
            'sierra-interactive': {
                accept: 'application/json',
                'Sierra-ApiKey': auth.apikey_accesstoken
            },
            'chime': {
                accept: 'application/json',
                'chime-token': auth.apikey_accesstoken
            },
            'kvcore': {
                accept: 'application/json',
                authorization: `Bearer ${auth.apikey_accesstoken}`
            }
        }
        this.authHeader = authHeader[this.crm];
    }

    async testConnection(){
        const testUrl = {
            'follow-up-boss': 'https://try.readme.io/https://api.followupboss.com/v1/me',
            'sierra-interactive': 'https://try.readme.io/https://api.sierrainteractivedev.com/users',
            'chime': `/api/chime-proxy?path=${encodeURIComponent('/me')}`,
            'kvcore': 'https://try.readme.io/https://api.kvcore.com/v2/public/users'
        }
        let response = await fetch(testUrl[this.crm], {
            method: 'GET',
            headers: this.authHeader,
            redirect: 'follow'
        });
        if(response.ok){
            
        let responseData = await response.json();

        // Check if the CRM is follow-up-boss and if the user is the owner
        if (this.crm === 'follow-up-boss' && responseData.isOwner === true) {
            return true;
        }

            return true;
        }
        return false;
    }

    async getStages(){
        const stagesUrl = {
            'follow-up-boss': 'https://thingproxy.freeboard.io/fetch/https://api.followupboss.com/v1/stages?limit=100&sort=id',
            'chime': '',
        }
        if(stagesUrl[this.crm]){
            let response = await fetch(stagesUrl[this.crm], {
                method: 'GET',
                headers: this.authHeader,
                redirect: 'follow'
            });
            if(response.status === 200){
                let responseData = await response.json();
                let tableData = [];
                responseData.stages.forEach(element => {
                    tableData.push({
                        key: `${element.id.toString()}-${element.name}`,
                        stage: element.name,
                        renCategory: ''
                    });
                });
                return tableData;
            }
            return Error('API connection error');
        } else {
            let tableData = [];
            switch (this.crm) {
                case 'chime':
                    ['New', 'Client', 'Closed', 'Sphere', 'Active', 'Contract', 'Archived', 'Prospect'].forEach(stage => {
                        tableData.push({
                            key: stage,
                            stage: stage,
                            renCategory: ''
                        });
                    });
                    break;
                case 'kvcore':
                    ['New', 'Client', 'Closed', 'Sphere', 'Active', 'Contract', 'Archived', 'Prospect'].forEach(stage => {
                        tableData.push({
                            key: stage,
                            stage: stage,
                            renCategory: ''
                        });
                    });
                    break;
                case 'sierra-interactive':
                    ['New', 'Qualify', 'Active', 'Prime', 'Pending', 'Archived', 'Watch', 'Closed', 'Junk', 'Do Not Contact', 'Blocked'].forEach(stage => {
                        tableData.push({
                            key: stage,
                            stage: stage,
                            renCategory: ''
                        });
                    });
                    break;
                    default:
                        break;
            }
            return tableData;
        }
    }
//ttps://try.readme.io/
//https://thingproxy.freeboard.io/fetch/
    async getUsers(){
        const usersUrl = {
            'follow-up-boss': 'https://try.readme.io/https://api.followupboss.com/v1/users?limit=500&sort=id',
            'sierra-interactive': 'https://try.readme.io/https://api.sierrainteractivedev.com/users',
            'chime': `/api/chime-proxy?path=${encodeURIComponent('/members')}`,
            'kvcore': 'https://try.readme.io/https://api.kvcore.com/v2/public/users'
        }
        let response = await fetch(usersUrl[this.crm], {
            method: 'GET',
            headers: this.authHeader,
            redirect: 'follow'
        });
        if(response.ok){
            const responseData = await response.json();
            let selectData = [];
            switch (this.crm) {
                case 'follow-up-boss':
                    responseData.users
                    .filter(user => user.isOwner !== true)
                    .forEach(user => {
                        selectData.push({
                            key: user.id,
                            crmId: user.id,
                            name: user.name,
                            role: user.role,
                            email: user.email
                        });
                    });
                    break;
                case 'sierra-interactive':
                    responseData.data.records.forEach(user => {
                        selectData.push({
                            value: `${user.id}-${user.name}`,
                            label: user.name
                        });
                    });
                    break;
                case 'chime':
                    responseData.members.forEach(user => {
                        selectData.push({
                            value: `${user.id}-${user.firstName} ${user.lastName}`,
                            label: `${user.firstName} ${user.lastName}`
                        });
                    });
                    break;                    
                case 'kvcore':
                    responseData.data.forEach(user => {
                        selectData.push({
                            value: `${user.id}-${user.first_name} ${user.last_name}`,
                            label: `${user.first_name} ${user.last_name}`
                        });
                    });
                    break;
                    default:
                        break;
            }
            return selectData;
        }
        return Error('API connection error');
    }

}

export default ApiConnection;